var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-form",
        {
          attrs: {
            model: _vm.formData,
            "label-position": "right",
            "label-width": "80px"
          }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "客户ID:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.userId,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "userId", _vm._n($$v))
                  },
                  expression: "formData.userId"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "提现账户类型:" } },
            [
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择", clearable: "" },
                  model: {
                    value: _vm.formData.withdrawType,
                    callback: function($$v) {
                      _vm.$set(_vm.formData, "withdrawType", $$v)
                    },
                    expression: "formData.withdrawType"
                  }
                },
                _vm._l(_vm.WithdrawTypeOptions, function(item, key) {
                  return _c("el-option", {
                    key: key,
                    attrs: { label: item.label, value: item.value }
                  })
                }),
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "金额:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.amount,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "amount", _vm._n($$v))
                  },
                  expression: "formData.amount"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "账号信息:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.info,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "info", $$v)
                  },
                  expression: "formData.info"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "状态:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.status,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "status", _vm._n($$v))
                  },
                  expression: "formData.status"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "外部订单号:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.no,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "no", $$v)
                  },
                  expression: "formData.no"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "付款时间:" } },
            [
              _c("el-date-picker", {
                attrs: { type: "date", placeholder: "选择日期", clearable: "" },
                model: {
                  value: _vm.formData.paidAt,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "paidAt", $$v)
                  },
                  expression: "formData.paidAt"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "支付流水号:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.paymentNo,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "paymentNo", $$v)
                  },
                  expression: "formData.paymentNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "交易结果:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.paidResult,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "paidResult", $$v)
                  },
                  expression: "formData.paidResult"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "orderNo字段:" } },
            [
              _c("el-input", {
                attrs: { clearable: "", placeholder: "请输入" },
                model: {
                  value: _vm.formData.orderNo,
                  callback: function($$v) {
                    _vm.$set(_vm.formData, "orderNo", $$v)
                  },
                  expression: "formData.orderNo"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.save } },
                [_vm._v("保存")]
              ),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.back } },
                [_vm._v("返回")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }